<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div
      v-if="footer_type == 'snno'"
      slot="footer"
    >
      <b-card title="">
        <p>Patrocinadores:</p>
        <img
          src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_01.png"
          style="max-height: 132px; width: auto;"
        >
        <img
          src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_02.png"
          style="max-height: 132px; width: auto;"
        >
        <img
          src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_03.png"
          style="max-height: 132px; width: auto;"
        >
        <img
          src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_04.png"
          style="max-height: 132px; width: auto;"
        >
        <img
          src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_05.png"
          style="max-height: 132px; width: auto;"
        >
        <img
          src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_06.png"
          style="max-height: 132px; width: auto;"
        >
        <img
          src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_07.png"
          style="max-height: 132px; width: auto;"
        >
      </b-card>
      <!--<img src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_08.png">-->
      <!--<img src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_09.png">-->
      <p class="mb-0">
        &copy; {{ client }} {{ new Date().getFullYear() }}
        <span class="float-md-right d-none d-md-block">
          OP3
        </span>
      </p>
    </div>
    <div
      v-else
      slot="footer"
    >
      <b-card title="">
        <p>Patrocinadores:</p>
      </b-card>
      <!--<img src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_08.png">-->
      <!--<img src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_09.png">-->
      <p class="mb-0">
        &copy; {{ client }} {{ new Date().getFullYear() }}
        <span class="float-md-right d-none d-md-block">
          OP3
        </span>
      </p>
    </div>
    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import LayoutVertical from '@core/utils/layouts/layout-vertical-congress/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BCard,
  },
  data() {
    return {
      client: '',
      footer_type: process.env.VUE_APP_FOOTER_CLIENT,
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted() {
    this.client = process.env.VUE_APP_CLIENT
  },
}
</script>

<style scoped>
  html .content {
    position: relative;
    transition: all .3s ease;
    backface-visibility: hidden;
    min-height: 0px !important;
}
</style>
