import store from '@/store'

const { congressAll } = store.state.congress
const congressChil = []
const menu = []
const user = JSON.parse(localStorage.getItem('userData'))
menu.push({
  title: 'Inicio',
  route: 'home_plataforma',
  icon: 'HomeIcon',
})
congressAll.forEach((element) => {
  if (element.path_event === window.location.pathname.split('/')[3]) {
    menu.push({
      title: `Aulas ${element.title}`,
      icon: 'TvIcon',
      route: {
        name: `${element.path_event}-previous`,
        params: { slug: element.path_event },
      },
    })
    // menu.push({
    //   title: 'Trabalho academico',
    //   icon: 'TvIcon',
    //   route: {
    //     name: 'submissionOfScientificWork',
    //     params: {
    //       congress: element.path_event,
    //       work_id: 1,
    //     },
    //   },
    // })
  }

  // if (element.active === 1) {
  //   menu.push({
  //     title: `Aulas ${element.title}`,
  //     icon: 'TvIcon',
  //     route: {
  //       name: `${element.path_event}-previous`,
  //       params: { slug: element.path_event },
  //     },
  //   })
  // }
})

export default menu
